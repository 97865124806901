import React, { ReactNode } from 'react'
import NavigationSideBarMobile from '@howdy/core/elements/NavigationSideBar/NavigationSideBarMobile'
import { Section } from '../NavigationSideBar/types'
import Footer from '../../../../components/Footer'

const LayoutMobile = ({
  children,
  menuSections,
  news,
}: {
  children: ReactNode
  menuSections: Section[]
  news: any
}) => {
  return (
    <div className='relative'>
      <div className='min-h-screen overflow-scroll'>
        <NavigationSideBarMobile menuSections={menuSections} news={news} />
        <div className=' bg-howdy-dark-wheat pb-[150px]'>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default LayoutMobile
