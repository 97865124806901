import React from 'react'

export default function OrdersCircle({ width = 42, height = 41, color = 'currentColor' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.5615 12.6777H11.8793C11.5107 12.6777 11.2119 12.9796 11.2119 13.3521V25.4902C11.2119 25.8627 11.5107 26.1646 11.8793 26.1646H26.5615C26.9301 26.1646 27.2289 25.8627 27.2289 25.4902V13.3521C27.2289 12.9796 26.9301 12.6777 26.5615 12.6777Z'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.2119 15.375H27.2289'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.5573 18.0723C22.5573 18.9665 22.2057 19.8241 21.5799 20.4564C20.9541 21.0887 20.1054 21.444 19.2204 21.444C18.3354 21.444 17.4867 21.0887 16.8609 20.4564C16.2351 19.8241 15.8835 18.9665 15.8835 18.0723'
        stroke={color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
