import React, { useCallback, useState } from 'react'
import { useSession } from 'next-auth/react'
import HowdyLogo from '../Icons/HowdyLogo'
import HowdyLogoInitial from '../Icons/HowdyLogoInitial'
import SignOut from '../Icons/SignOut'
import clsx from 'clsx'
import { Section } from './types'
import SectionsBlock from './SectionsBlock'
import { signOut } from '@howdy/core/lib/auth'
import { useRouter } from 'next/navigation'
import Button from '../Button'
import { useUser } from '../../../../contexts/userContext'
// TODO: make section dynamic receiving them from props
// const NavigationSideBarDesktop = ({sections}:{sections: Section[]}) => {

const NavigationSideBarDesktop = ({
  menuSections,
  news,
}: {
  menuSections: Section[]
  news: any
}) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { push } = useRouter()
  const { userState: userLogged } = useUser()
  const { status } = useSession()

  const toggleIsNavExpanded = (value: boolean) => {
    setIsNavExpanded(value)
  }
  const logOut = useCallback(() => {
    return window?.signOutUrl ? push(window.signOutUrl) : signOut()
  }, [push])
  return (
    <nav
      className={clsx(
        'relative z-50 flex h-full flex-col justify-between bg-white pb-3 pl-3 pr-4 pt-11 shadow-2xl transition-all duration-75',
        {
          'w-52': isNavExpanded,
          'w-16': !isNavExpanded,
        },
      )}
      onMouseOver={() => toggleIsNavExpanded(true)}
      onMouseLeave={() => toggleIsNavExpanded(false)}
    >
      <div>
        <div className='ml-2'>
          {isNavExpanded ? <HowdyLogo className='h-[34px] w-[91px]' /> : <HowdyLogoInitial />}
        </div>
        <div
          className={clsx('mt-9 max-h-[80vh] p-[-3px]', {
            'overflow-y-auto': isNavExpanded,
          })}
        >
          <SectionsBlock sections={menuSections} isNavExpanded={isNavExpanded} news={news} />
        </div>
      </div>
      {(userLogged?.data?.id || status === 'authenticated') && (
        <span className='flex cursor-pointer items-center gap-2 text-sm font-bold' onClick={logOut}>
          {isNavExpanded && <Button style={'tertiary'}>Log out</Button>}{' '}
          <div className='text-howdy-primary-green'>
            <SignOut />
          </div>
        </span>
      )}
    </nav>
  )
}

export default NavigationSideBarDesktop
