import Input from '@howdy/core/elements/Input'
import ReactFlagsSelect from 'react-flags-select'
import { Controller } from 'react-hook-form'

export default function BillingInfoForm({ register, errors, control }) {
  return (
    <div className='mt-5 w-full md:flex md:flex-col'>
      <Input
        label='Company legal name'
        placeholder='Company legal name'
        name='Company legal name'
        input={{
          ...register('companyLegalName', {
            required: 'Required',
            maxLength: {
              value: 80,
              message: 'Maximum length is 80',
            },
          }),
        }}
        required='Required'
        error={errors && errors['companyLegalName']?.message}
      />
      <Input
        label='Billing admin email'
        placeholder='Billing admin email'
        name='Billing admin email'
        input={{
          ...register('billingEmail', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address',
            },
            maxLength: {
              value: 80,
              message: 'Maximum length is 80',
            },
            required: 'Required',
          }),
        }}
        required='Required'
        error={errors && errors['billingEmail']?.message}
      />
      <Input
        label='Billing admin phone'
        placeholder='Billing admin phone'
        name='Billing admin phone'
        input={{
          ...register('billingContactPhone', {
            maxLength: {
              value: 80,
              message: 'Maximum length is 80',
            },
            required: 'Required',
          }),
        }}
        required='Required'
        error={errors && errors['billingContactPhone']?.message}
      />
    </div>
  )
}
