import { Form, Field, useForm } from 'react-final-form'
import ReactFlagsSelect from 'react-flags-select'
import classNames from 'classnames'

import Input from '@howdy/core/elements/Input'
import Button from '@howdy/core/elements/Button'
import CountryCodes from '../../public/companyInfo/countriesDialCodes.json'

const isValidInternationalPhone = (phonenumber) => {
  // Regex to check valid International Phone Numbers
  const regex = /^\+[1-9]\d{10,14}$/
  return !!phonenumber && regex.test(phonenumber)
}

const PhoneForm = ({ sendVerificationCode, handleBack, phoneNumber }) => {
  return (
    <div className='h-full'>
      <Form
        initialValues={{
          country: 'US',
          code: '+1',
        }}
        validate={async ({ code, phone }) => {
          if (!phone) {
            return {
              // [FORM_ERROR]: 'Please enter your phone number'
            }
          }
          if (!isValidInternationalPhone(code + phone)) {
            return {
              phone: 'Please review your phone number',
            }
          }
        }}
        onSubmit={({ code, phone }) => {
          if (code && phone) {
            sendVerificationCode(code + phone)
          }
        }}
      >
        {({ handleSubmit, errors, values }) => (
          <form onSubmit={handleSubmit} className='flex h-full flex-col justify-between'>
            <div>
              <h1 className='text-lg text-howdy-primary-green md:text-3xl lg:text-sm '>
                VERIFY INFO
              </h1>
              <h1 className='mb-4 text-2xl font-bold md:text-5xl'>Enter your phone</h1>
              <p className='text-base'>
                Verify you're a real person before we schedule our beloved candidates with you!
              </p>
              <div className='flex w-full flex-col gap-2'>
                <div className='mt-4 flex w-full flex-col gap-4 sm:flex-row'>
                  <div className='w-full'>
                    <div className='-mt-2 text-xs'>
                      <ReactFinalPhoneForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'flex w-full gap-8',
                'items-center xsm:mb-4 xsm:mt-4 xsm:flex-row xsm:justify-center',
              )}
            >
              <div>
                <Button
                  style='tertiary'
                  onClick={() => {
                    handleBack()
                  }}
                >
                  Back
                </Button>
              </div>
              <div>
                <Button disabled={!values.phone || errors.phone} type='submit'>
                  Send
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

export default PhoneForm

const countries = {
  US: '+1',
  GB: '+44',
  CA: '+1',
  IL: '+972',
  AR: '+54',
  UY: '+598',
  BR: '+55',
  MX: '+52',
  CO: '+57',
  ES: '+34',
  FR: '+33',
  PT: '+351',
  NL: '+31',
  BE: '+32',
  CL: '+56',
}

const whiteListedCountries = Object.keys(countries)

function ReactFinalPhoneForm() {
  const form = useForm()
  return (
    <div className='flex flex-row'>
      <Field
        name='country'
        render={({ input }) => (
          <ReactFlagsSelect
            countries={whiteListedCountries}
            placeholder=' '
            showOptionLabel={false}
            showSelectedLabel={true}
            customLabels={countries}
            className='mr-2 h-[54px] rounded-lg bg-white !pb-0 [&>button]:h-full'
            selectButtonClassName='focus:border-howdy-primary-green focus:text-howdy-primary-green focus:ring-0'
            selected={input.value}
            onSelect={(code) => {
              form.change('code', CountryCodes.find((country) => country.isoCode == code).value)
              input.onChange(code)
            }}
            showSecondarySelectedLabel={false}
          />
        )}
      />
      <Field
        name='phone'
        render={({ input, meta }) => (
          <Input
            {...input}
            placeholder='Add your phone number'
            error={meta.error && meta.touched ? meta.error : undefined}
          />
        )}
      />
    </div>
  )
}
