import React, { ReactNode } from 'react'
import NavigationSideBar from '@howdy/core/elements/NavigationSideBar'
import { Section } from '../NavigationSideBar/types'
import Footer from '../../../../components/Footer'

const LayoutDesktop = ({
  children,
  menuSections,
  router,
  news,
}: {
  children: ReactNode
  menuSections: Section[]
  router: any
  news: any
}) => {
  return (
    <div className='relative h-screen overflow-hidden'>
      <div className='absolute left-0 top-0 z-50 h-full'>
        <NavigationSideBar menuSections={menuSections} router={router} news={news} />
      </div>

      <div className='ml-16 h-full overflow-y-auto bg-howdy-dark-wheat'>{children}</div>
      <Footer />
    </div>
  )
}

export default LayoutDesktop
