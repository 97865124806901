import CountryArgentina from './CountryArgentina'
import CountryBrasil from './CountryBrasil'
import CountryChile from './CountryChile'
import CountryColombia from './CountryColombia'
import CountryEcuador from './CountryEcuador'
import CountryEEUU from './CountryEEUU'
import CountryMexico from './CountryMexico'
import CountryPeru from './CountryPeru'
import CountryPlaceholder from './CountryPlaceholder'
import CountryUruguay from './CountryUruguay'
import CountryVenezuela from './CountryVenezuela'

const FlagIcons = {
  Argentina: CountryArgentina,
  Brasil: CountryBrasil,
  Chile: CountryChile,
  Colombia: CountryColombia,
  Ecuador: CountryEcuador,
  'United States': CountryEEUU,
  USA: CountryEEUU,
  Mexico: CountryMexico,
  Peru: CountryPeru,
  Placeholder: CountryPlaceholder,
  Uruguay: CountryUruguay,
  Venezuela: CountryVenezuela,

  AR: CountryArgentina,
  BR: CountryBrasil,
  CL: CountryChile,
  CO: CountryColombia,
  EC: CountryEcuador,
  US: CountryEEUU,
  MX: CountryMexico,
  PE: CountryPeru,
  UY: CountryUruguay,
  VE: CountryVenezuela,
}

export default FlagIcons
