import React, { ReactNode, useEffect, useState } from 'react'
import { SessionProvider } from 'next-auth/react'
import LayoutDesktop from './LayoutDesktop'
import LayoutMobile from './LayoutMobile'
import { Section } from '../NavigationSideBar/types'
import { useUser } from '../../../../contexts/userContext'
import useRecommendations from '../../../../hooks/useRecomendations'
import useMobile from '../../../../hooks/useMobile'

const Layout = ({
  children,
  menuSections,
  hideMenu,
  router,
}: {
  children: ReactNode
  menuSections: Section[]
  hideMenu?: boolean
  router?: any
}) => {
  const { isMobile } = useMobile()
  const { recommendations, getRecommendations } = useRecommendations()
  const { userState: user } = useUser()

  useEffect(() => {
    getRecommendations()
  }, [user])

  if (isMobile === null) {
    return <></>
  }

  if (hideMenu) {
    return <>{children}</>
  }
  return (
    <SessionProvider>
      {isMobile ? (
        <LayoutMobile
          menuSections={menuSections}
          news={{ 'My Candidates': recommendations.some((r) => r.isNew) }}
        >
          {children}
        </LayoutMobile>
      ) : (
        <LayoutDesktop
          menuSections={menuSections}
          router={router}
          news={{ 'My Candidates': recommendations.some((r) => r.isNew) }}
        >
          {children}
        </LayoutDesktop>
      )}
    </SessionProvider>
  )
}

export default Layout
